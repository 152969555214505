import ProjectSubtype from '@/app/models/projectSubTypes';
import { useGetActiveBuildingsQuery } from '@/app/services/buildingsApi';
import { useListSubtypesQuery } from '@/app/services/projectSubtypeApi';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface BuildingLinksGridProps {
  selectedBuilding: {
    building: {
      id: number;
      name: string;
      acronym: string;
      active: boolean;
    };
    subTypes: Array<{
      code: string;
      name: string;
    }>;
  }[];
  onChangeBuildings: (
    newValues: {
      building: {
        id: number;
        name: string;
        acronym: string;
        active: boolean;
      };
      subTypes: Array<{
        code: string;
        name: string;
      }>;
    }[],
  ) => void;
}

export default function BuildingLinksGrid({ selectedBuilding, onChangeBuildings }: BuildingLinksGridProps) {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    selectedBuilding.map((item) => item.building.id),
  );

  const { data: buildings } = useGetActiveBuildingsQuery();
  const { data: subTypes } = useListSubtypesQuery({ searchTerm: "", page: 0, size: 100, sortDirection: 'ASC', sortKey: 'name', enabled: true });

  useEffect(() => {
    setSelectionModel(selectedBuilding.map((item) => item.building.id));
  }, [selectedBuilding]);

  const useColumns = (): GridColDef[] => {
    const { t } = useTranslation(['expense', 'common']);

    return [
      {
        field: 'acronym',
        headerName: t('acronym'),
        width: 100,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: t('common:building'),
        width: 150,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'sharingTypes',
        headerName: 'Tipos de Rateio',
        width: 600,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { id, value, row } = params;

          return (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {subTypes?.content.map(({ code, name }) => (
                <FormControlLabel
                  key={code}
                  control={
                    <Checkbox
                      checked={
                        row && row.subtype ? row.subtype.map((r: ProjectSubtype) => r.code).includes(code.toUpperCase()) : false
                      }
                      onChange={(event) => {
                        let updatedBuildings = [...selectedBuilding];
                        if (event.target.checked) {
                          updatedBuildings = updatedBuildings.map((building) =>
                            building.building.id !== row.id
                              ? building
                              : {
                                ...building,
                                subTypes: [
                                  ...building.subTypes,
                                  { code: code.toUpperCase(), name: name.toUpperCase() },
                                ],
                              },
                          );
                        } else {
                          updatedBuildings = updatedBuildings.map((building) =>
                            building.building.id !== row.id
                              ? building
                              : {
                                ...building,
                                subTypes: [
                                  ...building.subTypes.filter((subtype) => subtype.code !== code.toUpperCase()),
                                ],
                              },
                          );
                        }
                        onChangeBuildings(updatedBuildings);
                      }}
                    />
                  }
                  label={name.toUpperCase()}
                  sx={{ margin: 0 }}
                />
              ))}
            </Box>
          );
        },
      },
    ];
  };
  const columns = useColumns();
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={
          buildings?.map((building) => {
            const foundSubtypes = selectedBuilding.find((sel) => sel.building.id === building.id)?.subTypes;
            return { ...building, subtype: foundSubtypes };
          }) || []
        }
        columns={columns}
        hideFooter
        hideFooterSelectedRowCount
        checkboxSelection
        disableRowSelectionOnClick
        sx={{
          border: 'none',
          '& .MuiCheckbox-root.Mui-checked': { color: '#ff7b00' },
          '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 900 },
        }}
        onRowSelectionModelChange={(newSelectionModel) => {
          let updatedBuildings = [...selectedBuilding];
          newSelectionModel.forEach((id) => {
            const found = selectedBuilding.find((build) => build.building.id === id);
            const completeBuilding = buildings?.find((build) => build.id === id);
            if (!found && completeBuilding) {
              updatedBuildings.push({
                building: { ...completeBuilding, active: completeBuilding.active ? completeBuilding.active : false },
                subTypes: [],
              });
            }
          });
          updatedBuildings = updatedBuildings.filter((item) => newSelectionModel.includes(item.building.id));
          onChangeBuildings(updatedBuildings);
          setSelectionModel(newSelectionModel);
        }}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
