import api from '@services/api';
import PaginationResponse from '../models/pagination';
import { Period } from '../models/period';

/**
 * <p>
 * API endpoints that covers all PROJECT Integration CRUD calls
 */
export const periodApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // LIST PERIODS
    Period: builder.query<PaginationResponse<Period>, number>({
      query: (size: number) => ({
        url: `/building/period/search?page=0&size=${size}&sortKey=date&sortDirection=DESC`,
        method: 'GET',
      }),
      providesTags: ['Period'],
    }),
  }),
});

export const { usePeriodQuery } = periodApi;
