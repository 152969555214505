import { ProjectCategoryResponse } from '@/app/models/costcenter';
import { useCreateProjectMutation } from '@/app/services/projectApi';
import { Button, Checkbox, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AmountField from '../common/amountField';
import CostCenterSearch from '../costcenter/CostCenterSearch';
import BuildingSelection from './BuildingSelection';

type AddProjectDrawerProps = {
  setOpen: (arg: boolean) => void;
  period: string
};


function AddProjectDrawer(props: AddProjectDrawerProps) {
  const { setOpen, period } = props;
  const { t } = useTranslation(['projects', 'common']);
  const [selectedCostCenter, setSelectedCostCenter] = useState<ProjectCategoryResponse | null>(null);
  const [buildingId, setBuildingId] = useState('');
  const [fixed, setFixed] = useState(false);
  const [goal, setgoal] = useState(null as unknown as number);
  const [createProject] = useCreateProjectMutation();

  const handleCreate = async () => {
    const response = await createProject({
      projectCode: selectedCostCenter?.code || '',
      buildingId: buildingId,
      goal,
      period: period,
      fix: fixed
    });
    if ('data' in response) {
      toast.success(
        t('project.created.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setOpen(false);
    } else {
      toast.error(
        t('error.creating.project', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <>
      <Typography variant="h1">{t('new.project')}</Typography>

      <div style={{ width: '100%' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          {t('project')}
        </Typography>
        <CostCenterSearch selectedCostCenter={null} setCostCenter={setSelectedCostCenter} />
      </div>
      <div style={{ width: '100%' }}>
        <Typography sx={{ marginTop: '24px' }} variant="h3">
          {t('common:building')}
        </Typography>
        <BuildingSelection buildingId={buildingId} setBuildingId={setBuildingId} />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div style={{ width: '50%' }}>
          <AmountField label={t('common:goal.currency')} value={goal} onChange={(e) => setgoal(e)} />
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <Typography sx={{ marginTop: '8px' }} variant="h3">
            {t('fixed')}
          </Typography>
          <Checkbox color="secondary" value={fixed} onChange={(e) => setFixed(e.target.checked)} />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        <Button
          variant="secondary"
          style={{
            marginRight: '16px',
          }}
          onClick={() => setOpen(false)}
        >
          {t('common:cancel')}
        </Button>
        <Button
          disabled={
            !buildingId ||
            !selectedCostCenter
          }
          onClick={handleCreate}
          variant="primary"
        >
          {t('common:add')}
        </Button>
      </div>
    </>
  );
}

export default AddProjectDrawer;
