import api from '@services/api';
import { Building, BuildingProject, CreateBuildingRequest, UpdateBuildingRequest } from '../models/buildings';

/**
 * <p>
 * API endpoints that covers all Headcount Integration CRUD calls
 */

type GetBuildings = {
  searchTerm: string;
  active: boolean;
};

export const headcountIntegrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET BY PERIOD
    getBuildings: builder.query<BuildingProject[], GetBuildings>({
      query: (input: GetBuildings) =>
        `/building/building-project-category/listAllActives?searchTerm=${input.searchTerm}&active=${input.active}`,
      providesTags: ['Buildings'],
    }),
    // GET All Active
    getActiveBuildings: builder.query<Building[], void>({
      query: () => `/building/building/listActives`,
    }),
    // PROCESS
    createBuilding: builder.mutation<void, CreateBuildingRequest>({
      query: (body) => ({
        url: `/building/building-project-category/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Buildings'],
    }),
    updateBuilding: builder.mutation<void, UpdateBuildingRequest>({
      query: (input: UpdateBuildingRequest) => ({
        url: `/building/building-project-category/update`,
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: ['Buildings'],
    }),
    disableBuilding: builder.mutation<void, number>({
      query: (id) => ({
        url: `/building/building/deactivate?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Buildings'],
    }),
  }),
});

export const {
  useGetBuildingsQuery,
  useGetActiveBuildingsQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDisableBuildingMutation,
} = headcountIntegrationApi;
