import { usePeriod } from '@/app/context/PeriodContext';
import { BuildingInfo } from '@/app/models/sharedAccountingExpense';
import { usePeriodQuery } from '@/app/services/periodApi';
import { useGetBuildingExpensesQuery } from '@/app/services/sharedAccountingExpenseApi';
import PeriodDetails from '@/components/periodDetails/PeriodDetails';
import BuildingProcess from '@/components/process/BuildingProcess';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


function displayCostCenter(costCenter: string) {
  let text = '';
  if (costCenter) {
    text = `${costCenter}`;
  }
  return text;
}

function Process() {
  const { i18n, t } = useTranslation(['process', 'common']);
  const { selectedPeriod, setSelectedPeriod } = usePeriod();

  const [shownBuilding, setShownBuilding] = useState(-1);
  const [size, setSize] = useState(5);
  const { data } = usePeriodQuery(size);

  useEffect(() => {
    if (!selectedPeriod && data?.content.length) {
      setSelectedPeriod(data.content[0]);
    }
  }, [data]);

  const { data: buildingSharedExpenses } = useGetBuildingExpensesQuery(selectedPeriod?.date ?? '', {
    skip: !selectedPeriod?.date,
  });


  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginRight: '20px',
    }}>
      <PeriodDetails />
      <Typography style={{ marginTop: '1.5rem' }} variant="h1">{t('common:process')}</Typography>
      <Typography variant="subtitle1" sx={{ marginTop: '8px' }}>
        {t('consult.process.costs')}
      </Typography>
      <div style={{ marginTop: '16px', marginRight: '24px' }}>
        {buildingSharedExpenses?.map((sharedExpense: BuildingInfo) => {
          const expensesCostCenter = sharedExpense.projectSharing;
          const infraCostCenter = sharedExpense.projectInfra;
          return (
            <BuildingProcess
              key={sharedExpense.buildingId}
              building={sharedExpense.building}
              buildingId={sharedExpense.buildingId}
              period={selectedPeriod?.date as string}
              expensesCostCenter={displayCostCenter(expensesCostCenter)}
              infraCostCenter={displayCostCenter(infraCostCenter)}
              status={sharedExpense.status}
              lastModifiedDate={sharedExpense?.lastProcessedDate}
              showExpenses={shownBuilding === sharedExpense.buildingId}
              setShowExpenses={(s) => {
                if (s) {
                  setShownBuilding(sharedExpense.buildingId);
                } else {
                  setShownBuilding(-1);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
export default Process;
