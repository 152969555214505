import { PAGE_PATHS, PAGE_ROLES } from '@constants/pages';

export interface SubMenu {
  id: number;
  label: string;
  navigate: string;
  roles: Array<string>;
}

export interface Menu {
  title: string;
  subMenus: Array<SubMenu>;
}

const MENU: Array<Menu> = [
  {
    title: 'configurations',
    subMenus: [
      { id: 1, label: 'common:buildings', navigate: PAGE_PATHS.BUILDINGS, roles: PAGE_ROLES.BUILDINGS },
      { id: 2, label: 'common:expenses', navigate: PAGE_PATHS.EXPENSES, roles: PAGE_ROLES.EXPENSES },
      { id: 3, label: 'common:projects', navigate: PAGE_PATHS.PROJECTS, roles: PAGE_ROLES.PROJECTS },
    ],
  },
  {
    title: 'common:sharing',
    subMenus: [{ id: 4, label: 'common:process', navigate: PAGE_PATHS.PROCESS, roles: PAGE_ROLES.PROCESS }],
  },
  {
    title: 'administration',
    subMenus: [
      { id: 7, label: 'product.permissions', navigate: PAGE_PATHS.PERMISSIONS, roles: PAGE_ROLES.PERMISSIONS },
    ],
  },
];

export default MENU;
