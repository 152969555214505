import { BuildingInfo, PreviewEntry } from '@app/models/sharedAccountingExpense';
import api from '@services/api';
import { AccountingExpenseRequest } from './IncomingAccountingExpeseApi';

interface PreviewRequest {
  buildingAcronym: string;
  month: number;
  year: number;
}
export interface FileResponse {
  filePath: string;
  fileName: string;
}

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBuildingExpenses: builder.query<Array<BuildingInfo>, string>({
      query: (input) => ({
        url: `/building/sharing-info/byPeriod?period=${input}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200,
      }),
      providesTags: ['Expense'],
    }),
    preview: builder.query<Array<PreviewEntry>, PreviewRequest>({
      query: (input: PreviewRequest) => ({
        url: `/accounting-expense-sharing/shared-accounting-expenses/preview?buildingAcronym=${input.buildingAcronym}&month=${input.month}&year=${input.year}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200,
      }),
      providesTags: ['Expense'],
    }),
    invalidateExpenses: builder.mutation<null, void>({
        queryFn: () => ({ data: null }),
        invalidatesTags: ['Expense'],
    }),
    generateSignedLink: builder.query<FileResponse, AccountingExpenseRequest>({
      query: (input: AccountingExpenseRequest) => ({
        url: encodeURI('/building/sharing-info/generateSignedLink'),
        method: 'POST',
        body: { period: input.period, buildingId: input.buildingId },
      }),
    }),
    procesSharedAccountingExpense: builder.mutation<void, AccountingExpenseRequest>({
      query: (input: AccountingExpenseRequest) => ({
        url: `/building/sharing-result/start`,
        method: 'POST',
        body: { period: input.period, buildingId: input.buildingId },
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const {
  useGetBuildingExpensesQuery,
  usePreviewQuery,
  useInvalidateExpensesMutation,
  useGenerateSignedLinkQuery,
  useProcesSharedAccountingExpenseMutation,
} = expenseApi;
