import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { Period } from '../models/period';

type PeriodContextType = {
  selectedPeriod: Period | undefined;
  setSelectedPeriod: Dispatch<SetStateAction<Period | undefined>>;
};

const PeriodContext = createContext<PeriodContextType | undefined>(undefined);

export function PeriodProvider({ children }: { children: React.ReactNode }) {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(undefined);

  const contextValue = useMemo(() => ({ selectedPeriod, setSelectedPeriod }), [selectedPeriod]);

  return <PeriodContext.Provider value={contextValue}>{children}</PeriodContext.Provider>;
}

export const usePeriod = () => {
  const context = useContext(PeriodContext);
  if (!context) {
    throw new Error('usePeriod must be used within a PeriodProvider');
  }
  return context;
};
