import { BuildingProject } from '@/app/models/buildings';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

function displayBuilding(building: BuildingProject) {
  return `${building.building.name} (${building.building.acronym})`;
}

interface BuildingSelectionProps {
  buildingId: string;
  setBuildingId: Dispatch<SetStateAction<string>>;
}

export default function BuildingSelection(props: BuildingSelectionProps) {
  const { buildingId, setBuildingId } = props;
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });
  const { t } = useTranslation('projects');

  return (
    <Select
      displayEmpty
      labelId="building-select-label"
      id="building-select"
      sx={{ height: '40px', width: '100%', fontSize: '13px', padding: 0, margin: 0 }}
      value={buildingId}
      onChange={(event: SelectChangeEvent<string>) => {
        setBuildingId(event.target.value);
      }}
    >
      <MenuItem disabled value="">
        <em>{t('select.building')}</em>
      </MenuItem>
      {buildings?.map((building) => (
        <MenuItem key={building.building.id} value={building.building.id} sx={{ fontSize: '13px' }}>
          {displayBuilding(building)}
        </MenuItem>
      ))}
    </Select>
  );
}
