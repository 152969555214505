import { PAGE_PATHS } from '@/app/constants/pages';
import Role from '@constants/roles';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('login');

  const isDev = process.env.REACT_APP_ENV === 'dev';
  const isSuper = keycloak.hasResourceRole(Role.SUPER, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isAdmin = keycloak.hasResourceRole(Role.ADMIN, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isControllership = keycloak.hasResourceRole(Role.CONTROLLERSHIP, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isPMO = keycloak.hasResourceRole(Role.PMO, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isShared = keycloak.hasResourceRole(Role.SHARED, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

  useEffect(() => {
    if (isControllership || isDev) {
      navigate(PAGE_PATHS.PROCESS);
    } else if (isShared || isDev) {
      navigate(PAGE_PATHS.BUILDINGS);
    } else if (isPMO || isDev) {
      navigate(PAGE_PATHS.PROJECTS);
    } else if (isSuper || isAdmin || isDev) {
      navigate(PAGE_PATHS.PERMISSIONS);
    } else {
      navigate(PAGE_PATHS.ROOT);
    }
  }, [isSuper, isAdmin, isControllership, isPMO, isShared, isDev, navigate]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography sx={{ marginTop: '42px', marginLeft: '50px' }}>{t('user.no.permission')}</Typography>
    </div>
  );
}
export default Redirect;
