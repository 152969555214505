import { IncomingAccountingExpense } from '@app/models/accountingExpense';
import api from '@services/api';

export interface AccountingExpenseRequest {
  buildingId: number;
  period: string | undefined;
}

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIncomingAccountingExpense: builder.query<Array<IncomingAccountingExpense>, AccountingExpenseRequest>({
      query: (input) => ({
        url: `/building/monthly-expense/byPeriodAndBuilding?period=${input.period}&buildingId=${input.buildingId}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Expense'],
    }),
    fetchIncomingAccountingExpense: builder.mutation<void, AccountingExpenseRequest>({
      query: (input: AccountingExpenseRequest) => ({
        url: `/building/monthly-expense/request`,
        method: 'POST',
        body: { buildingId: input.buildingId, period: input.period },
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const { useGetIncomingAccountingExpenseQuery, useFetchIncomingAccountingExpenseMutation } = expenseApi;
