import { PeriodProvider, usePeriod } from '@/app/context/PeriodContext';
import { usePeriodQuery } from '@/app/services/periodApi';
import { getCurrentPeriod } from '@/app/utils/dateUtils';
// import TotalGoalsPage from '@/pages/dashboard/totalgoals';
import PersonProductRoles from '@/pages/personProductRoles';
import Projects from '@/pages/projects';
import Redirect from '@/pages/redirecting';
import { PAGE_PATHS, PAGE_ROLES } from '@app/constants/pages';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import Buildings from '@pages/buildings';
import Expenses from '@pages/expenses';
import Layout from '@pages/layout';
import Login from '@pages/login';
import Process from '@pages/process';
import { useEffect, useState } from 'react';

import { Route, Routes, useSearchParams } from 'react-router-dom';

function AppRoute() {
  const currentPeriod = getCurrentPeriod();

  const [searchParams] = useSearchParams();
  const numberOfPeriods = Number(searchParams.get('numberOfPeriods'));

  return (
    <PeriodProvider>
      <Routes>
        <Route path={PAGE_PATHS.LOGIN} element={<Login />} />
        <Route element={<RequireLogin />}>
          <Route element={<Layout />}>
            <Route path={PAGE_PATHS.ROOT} element={<Redirect />} />

            <Route>
              <Route element={<RequireAuth roles={PAGE_ROLES.BUILDINGS} />}>
                <Route path={PAGE_PATHS.BUILDINGS} element={<Buildings />} />
              </Route>

              <Route element={<RequireAuth roles={PAGE_ROLES.EXPENSES} />}>
                <Route path={PAGE_PATHS.EXPENSES} element={<Expenses />} />
              </Route>

              <Route element={<RequireAuth roles={PAGE_ROLES.PROJECTS} />}>
                <Route path={PAGE_PATHS.PROJECTS} element={<Projects />} />
              </Route>
            </Route>

            <Route element={<RequireAuth roles={PAGE_ROLES.PROCESS} />}>
              <Route path={PAGE_PATHS.PROCESS} element={<Process />} />
            </Route>

            <Route element={<RequireAuth roles={PAGE_ROLES.PERMISSIONS} />}>
              <Route path={PAGE_PATHS.PERMISSIONS} element={<PersonProductRoles />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </PeriodProvider>
  );
}

export default AppRoute;
