import { ProjectResponse } from '@app/models/costcenter';
import api from '@services/api';
import { PaginatedResponse } from '../models/common';

/**
 * <p>
 * API endpoints that covers all CostCenter CRUD calls
 */
export const costcenterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    costCenters: builder.query<PaginatedResponse<ProjectResponse>, string>({
      query: (searchTerm) => `/building/project/search?searchTerm=${searchTerm}`,
      providesTags: ['CostCenter'],
    }),
  }),
});

export const { useCostCentersQuery } = costcenterApi;
