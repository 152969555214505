import { HeadcountIntegration } from '@app/models/headcountIntegration';
import api from '@services/api';

/**
 * <p>
 * API endpoints that covers all Headcount Integration CRUD calls
 */
export const headcountIntegrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // PROCESS
    process: builder.mutation<HeadcountIntegration, string>({
      query: (period) => ({
        url: `/building/building-project-period/authorize?period=${period}`,
        method: 'POST',
      }),
      invalidatesTags: ['HeadcountIntegration', 'Project'],
    }),
  }),
});

export const { useProcessMutation } = headcountIntegrationApi;
