import { BuildingProject } from './buildings';
import { CostCenter } from './costcenter';

export interface CostCenterCategory {
  costCenter: CostCenter;
  category: string;
}

export interface BuildingCostCenter {
  building: BuildingProject;
  costCenterCategoryList: Array<CostCenterCategory>;
}

export interface UpdateBuildingCostCenterRequest {
  buildingAcronym: string;
  costCenterCategoryList: Array<CostCenterCategory>;
}

export enum BuildingCostCenterCategory {
  SHARING = 'SHARING',
  INFRA = 'INFRA',
}
