import {
  useFetchIncomingAccountingExpenseMutation,
  useGetIncomingAccountingExpenseQuery,
} from '@/app/services/IncomingAccountingExpeseApi';
import {
  useGenerateSignedLinkQuery,
  useInvalidateExpensesMutation,
  useProcesSharedAccountingExpenseMutation,
} from '@/app/services/sharedAccountingExpenseApi';
import { IncomingAccountingExpense, SharedExpensesProcessingStatus } from '@app/models/accountingExpense';
import refresh from '@icons/refresh.svg';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LateralDrawer from '../common/LateralDrawer';
import ConfirmProcess from './ConfirmProcess';

import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import StatusDisplay from './StatusDisplay';

function FilePathLink(params: { period: string; buildingId: number }) {
  const { period, buildingId } = params;
  const { t } = useTranslation(['process']);
  const { data: file } = useGenerateSignedLinkQuery({
    period,
    buildingId,
  });
  return (
    <div style={{ minWidth: '135px', textAlign: 'start' }}>
      {file?.filePath && (
        <div
          style={{
            width: '135px',
            textAlign: 'start',
          }}
        >
          <Button href={file.filePath} variant="tertiary" sx={{ marginLeft: '16px' }}>
            {t('Baixar Arquivo')}
          </Button>
        </div>
      )}
    </div>
  );
}
const POLLING_INTERVAL = 3000; // 3 seconds

const MIN_POLLING_INTERVAL = 5000; // 5 seconds
const MAX_POLLING_INTERVAL = MIN_POLLING_INTERVAL * 4; //4 seconds

type BuildingProcessProps = {
  building: string;
  expensesCostCenter: string;
  infraCostCenter: string;
  status: string;
  period: string;
  showExpenses: boolean;
  setShowExpenses: (value: boolean) => void;
  lastModifiedDate: string;
  buildingId: number;
};

const formatter = new Intl.NumberFormat('pt-BR');

export default function BuildingProcess(props: BuildingProcessProps) {
  const {
    building,
    expensesCostCenter,
    infraCostCenter,
    status,
    showExpenses,
    setShowExpenses,
    lastModifiedDate,
    period,
    buildingId,
  } = props;
  const { t } = useTranslation(['process', 'common']);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fetchIncoming] = useFetchIncomingAccountingExpenseMutation();
  const [processExpenses] = useProcesSharedAccountingExpenseMutation();
  const [invalidateExpenses] = useInvalidateExpensesMutation()
  const [loadingIncoming, setLoadingIncoming] = useState(false);
  const stopPooling = useRef(false);
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [pollingIntervalIncoming, setPollingIntervalIncoming] = useState(0);
  const [disabledProcessingButton, setDisabledProcessingButton] = useState(false);
  const { data: incomingAccountingExpenses } = useGetIncomingAccountingExpenseQuery(
    {
      period,
      buildingId,
    },
    {
      pollingInterval: pollingIntervalIncoming,
      skip: !showExpenses || stopPooling.current,
    },
  );

  async function handleFetchExpenses() {
    stopPooling.current = false;

    const response = await fetchIncoming({ buildingId, period });

    try {
      setLoadingIncoming(true);

      if (incomingAccountingExpenses && incomingAccountingExpenses?.length > 0) {
        setPollingIntervalIncoming(0);
        setLoadingIncoming(false);
      }
      else {
        setPollingIntervalIncoming(MIN_POLLING_INTERVAL);
      }
    } catch (error) {
      console.error('Erro ao buscar despesas:', error);
    } finally {
      setTimeout(() => {
        setLoadingIncoming(false);
        setPollingIntervalIncoming(0);
      }, MAX_POLLING_INTERVAL);
    }
  }


  type GroupedItem = {
    code: string;
    description: string;
    expenses: { date: string; value: number }[];
  };

  function groupExpenses(items: IncomingAccountingExpense[]): GroupedItem[] {
    const grouped = items.reduce((acc, item) => {
      const key = item.code;

      if (!acc[key]) {
        acc[key] = { code: item.code, description: item.description, expenses: [] };
      }

      acc[key].expenses.push({ date: item.date, value: item.value });

      return acc;
    }, {} as Record<string, GroupedItem>);

    return Object.values(grouped);
  }


  async function handleProcessExpenses() {
    stopPooling.current = false;
    setDisabledProcessingButton(true);
    setLoadingProcessing(true);
    await processExpenses({
      buildingId,
      period,
    });

    const intervalProcessing = setInterval(() => {
      invalidateExpenses()

      setDisabledProcessingButton(true);

      if (stopPooling.current || status === SharedExpensesProcessingStatus.PROCESSED) {
        clearInterval(intervalProcessing)
        setDisabledProcessingButton(false);
        setLoadingProcessing(false);
        if (!stopPooling.current) {
          toast.success(t('Processado com sucesso!'), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }

    }, MIN_POLLING_INTERVAL);

    toast.warn(t('Enviado para processamento...'), {
      position: toast.POSITION.TOP_RIGHT,
    });

    setTimeout(() => {
      clearInterval(intervalProcessing)
      setDisabledProcessingButton(false);
      setLoadingProcessing(false);

      if (!stopPooling.current) {
        toast.success(t('Processado com sucesso!'), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }, MAX_POLLING_INTERVAL)
    setShowConfirm(false);
  }

  useEffect(() => {
    const hasExpenses = incomingAccountingExpenses && incomingAccountingExpenses?.length > 0;
    setDisabledProcessingButton(!hasExpenses);

  }, [incomingAccountingExpenses]);

  return (
    <>
      <Card
        sx={{
          width: '100%',
          border: '1px solid #E4E4E4',
          marginBottom: '16px',
          ':hover': { border: '1px solid #888888' },
        }}
        onClick={() => setShowExpenses(!showExpenses)}
      >
        <CardContent sx={{ padding: '24px', minWidth: 1000 }}>
          <Grid container spacing={1} columns={16} alignItems="flex-start">
            <Grid item md={2}>
              <Box sx={{ marginRight: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('common:building')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 550 }}>{building}</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={5}>
              <Box sx={{ marginRight: '24px', marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('common:correlates.cc')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 600 }}>
                  {expensesCostCenter}
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={5}>
              <Box sx={{ marginRight: '24px', marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('common:infra.cc')}
                </Typography>
                <Typography sx={{ color: '#4b4b4b', fontSize: '13px', fontWeight: 600 }}> {infraCostCenter}</Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ border: '1px solid #CCCCCC' }} />
            <Grid item md={3}>
              <Box sx={{ marginLeft: '24px' }}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('common:status')}
                </Typography>
                <StatusDisplay status={status} lastModifiedDate={lastModifiedDate} />
              </Box>
            </Grid>
            <Grid
              item
              md={0.9}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              margin="auto"
            >
              <div
                style={{
                  border: 'solid #707070',
                  borderWidth: '0 2px 2px 0',
                  width: '12px',
                  height: '12px',
                  transform: 'rotate(316deg)',
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <LateralDrawer open={showExpenses} setOpen={setShowExpenses} position="right" width="700px">
        <>
          <Typography variant="h1" style={{ fontSize: '21px' }}>
            {building}
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('common:period')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{period}</Typography>
            </div>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('common:status')}:`}</Typography>
              <StatusDisplay status={status} lastModifiedDate={lastModifiedDate} />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
            <div style={{ width: '50%', paddingRight: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('common:correlates.cc')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{expensesCostCenter}</Typography>
            </div>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle2" color="text.secondary">{`${t('common:infra.cc')}:`}</Typography>
              <Typography sx={{ color: '#4b4b4b', fontWeight: 600 }}>{infraCostCenter}</Typography>
            </div>
          </div>
          <Divider sx={{ marginTop: '16px' }} />
          <TableContainer
            sx={{
              marginTop: '24px',
              marginBottom: '24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              height: '100%',
            }}
          >
            <Table stickyHeader aria-label="Expenses table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    Despesa
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    Valor (R$)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ flexGrow: 1, overflow: 'auto' }}>
                {(loadingIncoming || loadingProcessing) && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '30vh',
                        }}
                      >
                        <CircularProgress size={50} color="secondary" />
                        {loadingIncoming ? (
                          <div style={{ fontSize: '20px', marginBottom: '20px' }}>{t('Buscando resultados')}</div>
                        ) : (
                          <div style={{ fontSize: '20px', marginBottom: '20px' }}>{t('Processando')}</div>
                        )}
                        <Typography>{t('Por favor aguarde...')}</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {!loadingIncoming && !loadingProcessing && incomingAccountingExpenses &&
                  groupExpenses(incomingAccountingExpenses)?.map((row) => (
                    <>
                      <TableRow key={row.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align="left"
                          sx={{ width: '50%', backgroundColor: '#FAFAFA', fontWeight: 'bold' }}
                        >{`${row.code} - ${row.description}`}</TableCell>
                        <TableCell align="left" sx={{ width: '50%', backgroundColor: '#FAFAFA' }}>
                          ---
                        </TableCell>
                      </TableRow>
                      {row.expenses.map((expense, i) => (
                        <TableRow
                          key={`${expense.value}-${expense.date}-${i}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            align="left"
                            sx={{ width: '50%', paddingLeft: '40px', backgroundColor: '#FFFFFF' }}
                          >{`Data: ${DateTime.fromFormat(expense.date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')}`}</TableCell>
                          <TableCell align="left" sx={{ width: '50%', backgroundColor: '#FFFFFF' }}>
                            {expense.value && formatter.format(expense.value)}
                          </TableCell>
                        </TableRow>
                      ))}

                    </>
                  ))}
                {!loadingIncoming && incomingAccountingExpenses?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <div style={{ width: '100%', alignItems: 'center', textAlign: 'center' }}>
                        <Typography sx={{ marginY: '20px' }}>
                          Ainda não existem despesas referentes a este período
                        </Typography>
                        <div style={{ width: '100%', alignItems: 'center' }}>
                          <Button variant="primary" onClick={handleFetchExpenses}>
                            Buscar Custos
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: '8px',
              marginTop: 'auto',
            }}
          >
            {incomingAccountingExpenses && incomingAccountingExpenses?.length > 0 && (
              <>
                <Button
                  onClick={() => handleFetchExpenses()}
                  variant="tertiary"
                  sx={{ marginLeft: '16px', marginRight: 'auto' }}
                  disabled={loadingIncoming}
                >
                  <img style={{ marginRight: '8px' }} src={refresh} alt={t('atualizar') as string} />
                  {t('Atualizar custos')}
                </Button>
                {status === SharedExpensesProcessingStatus.PROCESSED && (
                  <FilePathLink period={period} buildingId={buildingId} />
                )}
              </>
            )}
            <Button onClick={() => {
              stopPooling.current = true;
              setPollingIntervalIncoming(0);
              setLoadingIncoming(false);
              setShowExpenses(false);
            }} variant="secondary" sx={{ marginLeft: '16px' }}>
              {t('common:cancel')}
            </Button>
            <Button
              onClick={() => setShowConfirm(true)}
              variant="primary"
              sx={{ marginLeft: '16px' }}
              disabled={disabledProcessingButton || loadingIncoming || incomingAccountingExpenses?.length === 0}
            >
              {t('common:process')}
            </Button>
          </div>
        </>
      </LateralDrawer >
      <ConfirmProcess
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleProcessExpenses}
        buildingAcronym={building}
      />
    </>
  );
}
