import { usePeriod } from '@/app/context/PeriodContext';
import { UpdateProject } from '@/app/models/project';
import { useGetBuildingsQuery } from '@/app/services/buildingsApi';
import { useListByPeriodQuery, useUpdateProjectsMutation } from '@/app/services/projectApi';
import TextButton from '@/components/buildings/TextButton';
import LateralDrawer from '@/components/common/LateralDrawer';
import SearchField from '@/components/common/SearchField';
import PeriodDetails from '@/components/periodDetails/PeriodDetails';
import AddProjectDrawer from '@/components/projects/AddProjectDrawer';
import ProjectsTable from '@/components/projects/table';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useThrottle } from 'react-use';

const ON_CHANGE_DELAY = 500; // 0.5 second

function Projects() {
  const { t } = useTranslation(['projects', 'common']);
  const [projectSearch, setProjectSearch] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [buildingSelect, setBuildingSelect] = useState('');
  const [updateProjects] = useUpdateProjectsMutation();
  const throttledSearchTerm = useThrottle(projectSearch, ON_CHANGE_DELAY);

  const [modifiedProjectsMap, setModifiedProjectsMap] = useState(new Map<string, Map<number, UpdateProject>>());

  const { selectedPeriod, setSelectedPeriod } = usePeriod();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const { data: projects } = useListByPeriodQuery({
    period: selectedPeriod?.date as string,
    page,
    size: perPage,
    searchTerm: throttledSearchTerm,
    buildingId: buildingSelect
  });
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: '', active: true });
  const [hasChanges, setHasChanges] = useState(false);

  const resetModification = () => {
    const newMap = new Map<string, Map<number, UpdateProject>>();
    newMap.set(selectedPeriod?.date as string, new Map<number, UpdateProject>());
    setModifiedProjectsMap(newMap);
  };

  useEffect(() => {
    if (!modifiedProjectsMap.has(selectedPeriod?.date as string)) {
      const newMap = new Map<string, Map<number, UpdateProject>>(
        Array.from(modifiedProjectsMap).map(([key, innerMap]) => [key, new Map(innerMap)]),
      );
      newMap.set(selectedPeriod?.date as string, new Map<number, UpdateProject>());
      setModifiedProjectsMap(newMap);
    }
  }, [selectedPeriod, modifiedProjectsMap]);

  useEffect(() => {
    if (
      modifiedProjectsMap.get(selectedPeriod?.date as string) &&
      modifiedProjectsMap.get(selectedPeriod?.date as string)?.size
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [modifiedProjectsMap, selectedPeriod]);

  const [isUpdateProject, setIsUpdateProject] = useState(false);

  const handleUpdate = async () => {
    if (selectedPeriod && modifiedProjectsMap && modifiedProjectsMap.has(selectedPeriod.date as string)) {
      const periodMap = modifiedProjectsMap.get(selectedPeriod.date as string) || new Map();
      const projectsToUpdate = Array.from(periodMap.values());
      const response = await updateProjects({ projects: projectsToUpdate });

      if ('data' in response) {
        toast.success(
          t('Projeto editado com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
      setIsUpdateProject(true);
    }
  };

  useEffect(() => {
    if (isUpdateProject) {
      setIsUpdateProject(false);
      resetModification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '20px',
      }}
    >
      <PeriodDetails />
      <Typography style={{ marginTop: '1.5rem' }} variant="h1">
        {t('common:projects')}
      </Typography>
      <div style={{ width: '100%', display: 'flex', marginTop: '14px', marginBottom: '16px', padding: 1 }}>
        <SearchField
          searchTerm={projectSearch}
          setSearchTerm={(v) => {
            setPage(0);
            setProjectSearch(v);
          }}
          placeholder={t('search.by.code.or.project.name')}
        />
        <Select
          displayEmpty
          labelId="building-filter"
          id="demo-simple-select-standard"
          value={buildingSelect}
          placeholder="selecione um prédio"
          onChange={(e) => {
            setBuildingSelect(e.target.value);
          }}
          size="small"
          sx={{ ml: 2, height: 35, fontSize: '14px', minWidth: 250 }}
        >
          <MenuItem key={-2} value="">
            <em>{t('all.buildings')}</em>
          </MenuItem>
          {buildings?.map((building) => (
            <MenuItem key={building.building.id} value={building.building.id}>
              {`${building.building.name} (${building.building.acronym})`}
            </MenuItem>
          ))}
        </Select>
        <TextButton
          style={{ marginLeft: 'auto' }}
          text={t('new.project.plus')}
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        <Button
          disabled={!hasChanges}
          onClick={() => {
            resetModification();
          }}
          sx={{ marginLeft: 6 }}
          variant="secondary"
        >
          {t('common:cancel')}
        </Button>
        <Button
          disabled={!hasChanges}
          onClick={() => {
            handleUpdate();
          }}
          sx={{ marginLeft: 2 }}
          variant="primary"
        >
          {t('common:save')}
        </Button>
      </div>
      <ProjectsTable
        items={projects?.content || []}
        onDelete={() => { }}
        onRowSelect={() => { }}
        page={page}
        handleChangePage={(e, v) => {
          setPage(v);
        }}
        rowsPerPage={perPage}
        handleChangeRowsPerPage={(v) => setPerPage(parseInt(v.target.value, 10))}
        itemCount={projects?.totalElements as number}
        selectedPeriod={selectedPeriod?.date as string}
        modifiedProjectsMap={modifiedProjectsMap}
        setModifiedProjectsMap={setModifiedProjectsMap}
      />
      <LateralDrawer open={showCreateModal} setOpen={setShowCreateModal} position="right">
        <AddProjectDrawer
          setOpen={setShowCreateModal}
          period={selectedPeriod?.date as string}
        />
      </LateralDrawer>
    </div>
  );
}
export default Projects;
