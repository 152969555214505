import { BuildingCostCenterCategory } from '@app/models/buildingCostCenter';
import { BuildingProject } from '@app/models/buildings';
import { ProjectCategoryResponse } from '@app/models/costcenter';
import ActionsMenu, { ActionMenuOption } from '@components/common/actionsMenu';
import ToggleIcon from '@icons/toggle.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type RowFormats = string | number | boolean;

interface Column {
  id: 'acronym' | 'name' | 'branch' | 'sharingCostCenter' | 'infraCostCenter' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: RowFormats) => JSX.Element;
}

type RowData = {
  id: number;
  acronym: string;
  name: string;
  branch: string;
  sharingCostCenter: string;
  infraCostCenter: string;
  actions: boolean;
};

const columns: readonly Column[] = [
  {
    id: 'acronym',
    label: 'acronym',
    minWidth: 60,
  },
  { id: 'name', label: 'common:name', minWidth: 130 },
  { id: 'branch', label: 'common:branch', minWidth: 120 },
  { id: 'sharingCostCenter', label: 'common:correlates.cc', minWidth: 130 },
  { id: 'infraCostCenter', label: 'common:infra.cc', minWidth: 130 },
  { id: 'actions', label: 'actions', minWidth: 80, align: 'center' },
];

const findBuildingCostCenterByCategory = (
  buildingCostCenters: Array<ProjectCategoryResponse>,
  category: BuildingCostCenterCategory,
) => {
  let result = '';
  if (buildingCostCenters) {
    const buildingCostCenterFound = buildingCostCenters.find(
      (buildingCostCenter) => buildingCostCenter.category === category,
    );
    if (buildingCostCenterFound) {
      result = `${buildingCostCenterFound.code} - ${buildingCostCenterFound.name}`;
    }
  }
  return result;
};

const findBuildingBranch = (buildingCostCenters: Array<ProjectCategoryResponse>) => {
  let result = '';
  if (buildingCostCenters) {
    const buildingCostCenterFound = buildingCostCenters.find(
      (buildingCostCenter) => buildingCostCenter.category === BuildingCostCenterCategory.SHARING,
    );
    if (buildingCostCenterFound) {
      result = `${buildingCostCenterFound.branchName}`;
    }
  }
  return result;
};

const createRows = (content: Array<BuildingProject>) =>
  content.map(
    (building: BuildingProject): RowData =>
    ({
      id: building.building.id,
      acronym: building.building.acronym,
      name: building.building.name.toUpperCase(),
      branch: findBuildingBranch(building.projects),
      sharingCostCenter: findBuildingCostCenterByCategory(building.projects, BuildingCostCenterCategory.SHARING),
      infraCostCenter: findBuildingCostCenterByCategory(building.projects, BuildingCostCenterCategory.INFRA),
      actions: true,
    } as RowData),
  );

interface BuildingTableRowProps {
  row: RowData;
  onClickRow: (clickedRow: RowData) => void;
  onDelete: (row: RowData) => void;
  isClosed: boolean;
  onReOpen: (row: RowData) => void;
}

function BuildingTableRow(props: BuildingTableRowProps) {
  const { row, onClickRow: handleOnClickRow, onDelete: handleOnDelete, isClosed, onReOpen } = props;

  const { t } = useTranslation(['expense', 'common']);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      style={{ minHeight: '40px', height: '40px', maxHeight: '40px', cursor: 'pointer' }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        const key = `${column.id}-${row.id}`;
        const isActionMenu = column.id === 'actions';
        const formattedValue = column.format ? column.format(value) : value;
        if (isActionMenu) {
          return (
            <TableCell key={key} align="center" component="td" scope="row" sx={{ fontSize: '13px', padding: 0 }}>
              <ActionsMenu
                actionList={
                  [
                    {
                      icon: <EditOutlinedIcon />,
                      label: t('edit.building'),
                      actionCallback: () => handleOnClickRow(row),
                    },
                    {
                      icon: <img src={ToggleIcon} alt={t('toggle.icon')} />,
                      label: !isClosed ? t('disable.building') : t('Habilitar Prédio'),
                      actionCallback: () => {
                        if (!isClosed) {
                          handleOnDelete(row);
                        } else {
                          onReOpen(row);
                        }
                        handleOnDelete(row);
                      },
                    },
                  ] as Array<ActionMenuOption>
                }
              />
            </TableCell>
          );
        }
        return (
          <TableCell
            key={key}
            component="td"
            scope="row"
            align="left"
            onClick={() => handleOnClickRow(row)}
            sx={{ height: 5, fontSize: 13 }}
          >
            {formattedValue}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

type BuildingTableProps = {
  items: Array<BuildingProject>;
  onRowSelect: (item: BuildingProject) => void;
  onDelete: (item: BuildingProject) => void;
  isClosed: boolean;
  onReOpen: (item: BuildingProject) => void;
};

export default function BuildingTable(props: BuildingTableProps) {
  const { t } = useTranslation('expense');
  const [rows, setRows] = useState<Array<RowData>>([]);
  const { items, onRowSelect, onDelete, isClosed, onReOpen } = props;

  useEffect(() => {
    setRows(createRows(items));
  }, [items]);

  const rowToPAccountingExpense = (row: RowData): BuildingProject => {
    const findItem = items.filter((item: BuildingProject) => item.building.id === row.id);
    if (findItem.length === 0) {
      toast.error(`${t('A despesa selecionado não existe mais...')}`, {
        position: toast.POSITION.TOP_RIGHT,
        delay: 1,
      });
    }
    return findItem[0];
  };

  const handleRowClick = (row: RowData) => {
    onRowSelect(rowToPAccountingExpense(row));
  };

  const handleDelete = (row: RowData) => {
    onDelete(rowToPAccountingExpense(row));
  };

  const handleReOpen = (row: RowData) => {
    onReOpen(rowToPAccountingExpense(row));
  };

  return (
    <TableContainer>
      <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Expense Table">
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row: RowData) => (
              <BuildingTableRow
                key={row.id}
                row={row}
                onClickRow={handleRowClick}
                onDelete={handleDelete}
                isClosed={isClosed}
                onReOpen={handleReOpen}
              />
            ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                {t('no.content.found')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
