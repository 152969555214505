import { BuildingCostCenterCategory } from '@app/models/buildingCostCenter';
import { BuildingProject } from '@app/models/buildings';
import { ProjectCategoryResponse } from '@app/models/costcenter';
import { useCreateBuildingMutation, useUpdateBuildingMutation } from '@app/services/buildingsApi';
import CostCenterSearch from '@components/costcenter/CostCenterSearch';
import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type AddBuildingDrawerProps = {
  handleClose: () => void;
  editing: boolean;
  selectedBuilding?: BuildingProject | null;
  handleDelete: (building: BuildingProject) => void;
  handleReOpen: (building: BuildingProject) => void;
  isClosed: boolean;
};

function findCostCenterByCategory(
  buildingCostCenters: Array<ProjectCategoryResponse>,
  category: BuildingCostCenterCategory,
) {
  return (
    buildingCostCenters.find(
      (buildingCostCenter: ProjectCategoryResponse) => buildingCostCenter.category === category,
    ) || null
  );
}

function AddBuildingDrawer(props: AddBuildingDrawerProps) {
  const { handleClose, editing, selectedBuilding, handleDelete, isClosed, handleReOpen } = props;
  const { t } = useTranslation(['buildings', 'expense', 'common']);

  const canEdit = editing && selectedBuilding;
  const [buildingAcronym, setBuildingAcronym] = useState(canEdit ? selectedBuilding?.building.acronym : '');
  const [buildingName, setBuildingName] = useState(canEdit ? selectedBuilding?.building.name : '');

  const [selectedInfraCostCenter, setSelectedInfraCostCenter] = useState(
    canEdit ? findCostCenterByCategory(selectedBuilding.projects, BuildingCostCenterCategory.INFRA) : null,
  );

  const [selectedSharingCostCenter, setSelectedSharingCostCenter] = useState(
    canEdit ? findCostCenterByCategory(selectedBuilding.projects, BuildingCostCenterCategory.SHARING) : null,
  );

  const [createBuilding] = useCreateBuildingMutation();
  const [updateBuilding] = useUpdateBuildingMutation();
  const title = editing ? t('expense:edit.building') : t('new.building');

  async function handleCreateBuilding() {
    const response = await createBuilding({
      building: {
        name: buildingName,
        acronym: buildingAcronym,
      },
      projects: [
        {
          code: selectedSharingCostCenter?.code as string,
          category: BuildingCostCenterCategory.SHARING,
        },
        {
          code: selectedInfraCostCenter?.code as string,
          category: BuildingCostCenterCategory.INFRA,
        },
      ],
    });
    if ('data' in response) {
      toast.success(
        t('building.successfully.added', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      handleClose();
    }
  }

  async function handleEditBuilding() {
    const response = await updateBuilding({
      building: {
        id: selectedBuilding?.building.id || 0,
        name: buildingName,
        acronym: buildingAcronym,
        active: selectedBuilding?.building.active || true,
      },
      projects: [
        {
          code: selectedSharingCostCenter?.code as string,
          category: BuildingCostCenterCategory.SHARING,
        },
        {
          code: selectedInfraCostCenter?.code as string,
          category: BuildingCostCenterCategory.INFRA,
        },
      ],
    });
    if ('data' in response) {
      toast.success(
        t('Prédio editado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      handleClose();
    }
  }

  function canAdd() {
    return buildingAcronym.length === 3 && buildingName.length > 0 && selectedInfraCostCenter && selectedSharingCostCenter;
  }

  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', marginRight: '16px' }}>
          <Typography sx={{ marginTop: '24px' }} variant="h2">
            {t('building.acronym')}
          </Typography>
          <TextField
            inputProps={{ maxLength: 3 }}
            value={buildingAcronym}
            onChange={(e) => setBuildingAcronym(e.target.value)}
            sx={{ marginTop: '4px', width: '100%' }}
            size="small"
            id="outlined-basic"
            variant="outlined"
          />
          <Typography variant="subtitle1" sx={buildingAcronym.length > 0 && buildingAcronym.length !== 3 ? { color: 'red' } : {}}>{t('the.acronym.must.contain.three.letters')}</Typography>
        </div>
        <div style={{ width: '100%' }}>
          <Typography sx={{ marginTop: '24px' }} variant="h2">
            {t('building.name')}
          </Typography>
          <TextField
            value={buildingName}
            onChange={(e) => setBuildingName(e.target.value)}
            sx={{ marginTop: '4px', width: '100%' }}
            size="small"
            id="outlined-basic"
            variant="outlined"
          />
        </div>
      </div>
      <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '24px' }} />
      <Typography sx={{ marginTop: '24px' }} variant="h3">
        {t('accounting.expense.cc')}
      </Typography>
      <Typography sx={{ marginTop: '4px' }} variant="subtitle2">
        {t('associate.cost.center.major.expenses')}
      </Typography>
      <CostCenterSearch selectedCostCenter={selectedSharingCostCenter} setCostCenter={setSelectedSharingCostCenter} />
      <Typography sx={{ marginTop: '24px' }} variant="h3">
        {t('common:infra.cc')}
      </Typography>
      <Typography sx={{ marginTop: '4px' }} variant="subtitle2">
        {t('associate.cost.center.cesar.expenses')}
      </Typography>
      <CostCenterSearch selectedCostCenter={selectedInfraCostCenter} setCostCenter={setSelectedInfraCostCenter} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        {canEdit &&
          (!isClosed ? (
            <Button variant="tertiary" style={{ marginRight: '24px' }} onClick={() => handleDelete(selectedBuilding)}>
              {t('expense:disable.building')}
            </Button>
          ) : (
            <Button variant="tertiary" style={{ marginRight: '24px' }} onClick={() => handleReOpen(selectedBuilding)}>
              {t('Habilitar Prédio')}
            </Button>
          ))}

        <Button
          variant="secondary"
          style={{
            marginRight: '16px',
          }}
          onClick={() => handleClose()}
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="primary"
          disabled={!canAdd()}
          onClick={() => {
            if (canEdit) {
              handleEditBuilding();
            } else {
              handleCreateBuilding();
            }
          }}
        >
          {canEdit ? t('common:save') : t('common:add')}
        </Button>
      </div>
    </>
  );
}

export default AddBuildingDrawer;
