import AccountingExpense, { FullAccountExpense, NewOrUpdateAccountingExpense } from '@app/models/accountingExpense';
import api from '@services/api';

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountingExpenses: builder.query<Array<AccountingExpense>, string>({
      query: (query: string) => ({
        url: `/building/building-expense/search?searchTerm=${query}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Expense'],
    }),
    getAccountingExpenseByCode: builder.query<FullAccountExpense, string>({
      query: (query: string) => ({
        url: `/building/building-expense/getByExpenseCode?code=${query}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
    }),
    createAccountingExpense: builder.mutation<void, NewOrUpdateAccountingExpense>({
      query: (accountingExpense: NewOrUpdateAccountingExpense) => ({
        url: '/building/building-expense/create',
        method: 'POST',
        body: accountingExpense,
      }),
      invalidatesTags: ['Expense'],
    }),

    updateAccountingExpense: builder.mutation<AccountingExpense, NewOrUpdateAccountingExpense>({
      query: (accountingExpense: NewOrUpdateAccountingExpense) => ({
        url: `/building/building-expense/update`,
        method: 'PUT',
        body: accountingExpense,
      }),
      invalidatesTags: ['Expense'],
    }),
  }),
});

export const {
  useGetAccountingExpensesQuery,
  useGetAccountingExpenseByCodeQuery,
  useCreateAccountingExpenseMutation,
  useUpdateAccountingExpenseMutation,
} = expenseApi;
