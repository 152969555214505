import { useGetAccountingExpensesQuery } from '@/app/services/accountingExpenseApi';
import TextButton from '@/components/buildings/TextButton';
import LateralDrawer from '@/components/common/LateralDrawer';
import SearchComponent from '@/components/common/search';
import ExpenseNewEditScreen from '@/components/expenses/NewEditScreen';
import ExpenseTable from '@/components/expenses/table';
import PeriodDetails from '@/components/periodDetails/PeriodDetails';
import AccountingExpense from '@app/models/accountingExpense';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ON_CHANGE_DELAY = 1000; // 1 second

export default function Expenses() {
  const { t } = useTranslation(['expense', 'common']);

  const [drawerStatus, setDrawerStatus] = useState(false);
  const [filter, setFilter] = useState('');
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<AccountingExpense | null>(null);

  const { data: accountingExpenseList } = useGetAccountingExpensesQuery(query);


  useEffect(() => {
    const timeOutId = setTimeout(() => setQuery(filter), ON_CHANGE_DELAY);
    return () => clearTimeout(timeOutId);
  }, [filter]);



  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '20px',
      }}
    >
      <PeriodDetails />
      <Box display="flex" flexDirection="column" sx={{ mt: 3, mr: 3 }}>
        <Typography variant="h1" sx={{ marginBottom: 2 }}>
          {t('common:expenses')}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <SearchComponent
            placeholder={t('search.expense.by.name.or.code')}
            onFilterChange={(value: string) => setFilter(value)}
          />
          <TextButton
            style={{ marginLeft: 'auto' }}
            text={`+ ${t('new.expense')}`}
            onClick={() => {
              setSelectedItem(null);
              setDrawerStatus(true);
            }}
          />
        </Box>
        <ExpenseTable
          items={accountingExpenseList || []}
          onRowSelect={(item) => {
            setSelectedItem(item);
            setDrawerStatus(true);
          }}
        />
        <LateralDrawer open={drawerStatus} setOpen={setDrawerStatus} position="right" width="650">
          <ExpenseNewEditScreen expense={selectedItem} setDrawerOpen={setDrawerStatus} />
        </LateralDrawer>
      </Box>
    </div>
  );
}

