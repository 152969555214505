import api from '@services/api';
import { PaginatedResponse } from '../models/common';
import ProjectSubtype, { GetProjectSubTypes } from '../models/projectSubTypes';

/**
 * <p>
 * API endpoints that covers all PROJECT Subtype CRUD calls
 */
export const projectSubtypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // SEARCH
    listSubtypes: builder.query<PaginatedResponse<ProjectSubtype>, GetProjectSubTypes>({
      query: (input: GetProjectSubTypes) =>
        `/building/project-subtype/search?page=${input.page}&size=${input.size
        }&sortKey=${input.sortKey}&sortDirection=${input.sortDirection}&enabled=${input.enabled}${input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''}`,
      providesTags: ['ProjectSubType'],
    }),
  }),
});

export const { useListSubtypesQuery } = projectSubtypeApi;
