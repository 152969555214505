import { usePeriod } from '@/app/context/PeriodContext';
import { Period } from '@/app/models/period';
import { useProcessMutation } from '@/app/services/headcountIntegrationApi';
import { usePeriodQuery } from '@/app/services/periodApi';
import { datetimeLabelFormated } from '@/app/utils/dateUtils';
import exclamation from '@icons/exclamation.svg';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Card, CardContent, Chip, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useKeycloak } from '@react-keycloak/web';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ProcessDialog from './ProcessDialog';

function PeriodDetails() {
  const { i18n, t } = useTranslation(['periodDetails', 'common']);

  const { selectedPeriod, setSelectedPeriod } = usePeriod();

  const { keycloak } = useKeycloak();

  const isPMO = keycloak.hasResourceRole('PMO', process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [size, setSize] = useState(5);

  const { data } = usePeriodQuery(size);
  const [processMutation] = useProcessMutation();

  useEffect(() => {
    if(!selectedPeriod && data?.content.length){
      setSelectedPeriod(data.content[0]);
    }
  }, [data, selectedPeriod, setSelectedPeriod]);

  async function handleProcess(date: string) {
    const response = await processMutation(date);
    if ('data' in response) {
      toast.success(
        t('import.process.authorized.success', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  }

  return (
    <Card sx={{ boxShadow: 3, display: 'flex', minHeight: '72px', alignItems: 'center' }}>
      {true && (
        <CardContent
          sx={{ display: 'flex', maxHeight: '24px', alignItems: 'center', paddingTop: '22px', width: '100%' }}
        >
          <Typography
            sx={{ margin: '0 24px', fontSize: 14, fontWeight: 'bold', color: '#4B4B4B' }}
            color="text.secondary"
            gutterBottom
          >
            {t('current.period')}
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={selectedPeriod || ({} as Period)}
              size="small"
              sx={{ width: '180px', fontSize: '13px' }}
              onChange={(event) => {
                if (event.target.value) {
                  setSelectedPeriod(event.target.value as Period);
                }
              }}
            >
              {data?.content.map((period) => (
                <MenuItem
                  sx={{
                    fontSize: '13px',
                    fontWeight: period === selectedPeriod ? 'bold' : 'normal',
                    color: period === selectedPeriod ? '#F97C06' : '#4B4B4B',
                  }}
                  key={period.date}
                  value={period as any}
                >
                  {`${period ? DateTime.fromISO(period.date).setLocale(i18n.language).monthLong : '--'}/${period ? DateTime.fromISO(period.date).year : '--'
                    }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              marginLeft: '40px',
              marginRight: '5px',
              fontSize: 14,
              fontWeight: 'bold',
              color: '#4B4B4B',
            }}
            color="text.secondary"
          >
            {`${t('common:headcount')}:`}
          </Typography>
          {selectedPeriod && (
            <>
              <Tooltip
                placement="top"
                title={
                  selectedPeriod.status === 'SYNCHRONIZED'
                    ? t(
                      'the.direct.sharing.system.has.sent.the.headcount.information.and.needs.authorization.to.start.the.import.process',
                    )
                    : t('authorized.means.the.selected.period.has.already.been.set.up.according.to.the.headcount.information.sent.by.direct.allocation')
                }
              >
                {selectedPeriod.status === 'SYNCHRONIZED' ? (
                  <Chip
                    sx={{
                      margin: '0 15px',
                      background: '#FFF7E1',
                      color: '#E3AC04',
                      maxHeight: '24px',
                    }}
                    icon={
                      <img
                        style={{
                          filter:
                            'invert(66%) sepia(58%) saturate(2224%) hue-rotate(9deg) brightness(101%) contrast(97%)',
                        }}
                        src={exclamation}
                        alt={t('exclamation.mark') as string}
                      />
                    }
                    label={t('synced')}
                  />
                ) : (
                  <Chip
                    sx={{
                      margin: '0 15px',
                      background: '#C8F8D3',
                      color: '#28A745',
                      maxHeight: '24px',
                    }}
                    label={t('authorized')}
                  />
                )}
              </Tooltip>
              <Typography
                sx={{
                  marginLeft: '12px',
                  marginRight: '5px',
                  fontSize: 13,
                  color: '#757575'
                }}
                color="text.secondary"
              >
                {`${t('last.sync')} ${datetimeLabelFormated(selectedPeriod.synchronizedDate)}`}
              </Typography>
              <Stack direction="row" alignItems="center" gap={1} sx={{ marginLeft: 'auto' }}>
                {selectedPeriod.status === 'SYNCHRONIZED' && (
                  <Tooltip
                    placement="top"
                    title={t(
                      'the.system.is.waiting.for.the.authorization.of.the.projects.to.be.allocated.to.the.appropriate.buildings.according.to.the.new.headcount.information.sent.by.direct.allocation.considering.the.settings.from.the.previous.period',
                    )}
                    arrow
                  >
                    <InfoIcon sx={{ marginRight: 1 }} inheritViewBox />
                  </Tooltip>
                )}
                <Button
                  variant="primary"
                  disabled={!selectedPeriod || selectedPeriod.status == 'AUTHORIZED' || !isPMO}
                  onClick={() => {
                    setOpenProcessDialog(true);
                  }}
                >
                  {t('authorize')}
                </Button>
              </Stack>
            </>
          )}
        </CardContent>
      )}
      <ProcessDialog
        open={openProcessDialog}
        handleClose={() => setOpenProcessDialog(false)}
        handleProcess={() => {
          if (data && data.content[0]) {
            handleProcess(data.content[0].date as string);
          }
        }}
      />
    </Card>
  );
}

export default PeriodDetails;
