import api from '@services/api';
import { PaginatedResponse } from '../models/common';
import Project, { CreateProject, GetProjectsByPeriod, UpdateProject } from '../models/project';

/**
 * <p>
 * API endpoints that covers all PROJECT Integration CRUD calls
 */
export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // SEARCH
    listByPeriod: builder.query<PaginatedResponse<Project>, GetProjectsByPeriod>({
      query: (input: GetProjectsByPeriod) =>
        `/building/building-project-period/search?period=${input.period}&page=${input.page}&size=${input.size
        }${input.buildingId ? `&buildingId=${input.buildingId}` : ""}${input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''}`,
      providesTags: ['Project'],
    }),
    // CREATE
    createProject: builder.mutation<Project, CreateProject>({
      query: (newProject) => ({
        url: `/building/building-project-period/create`,
        method: 'POST',
        body: newProject,
      }),
      invalidatesTags: ['Project'],
    }),
    // UPDATE
    updateProjects: builder.mutation<{ projects: Project[] }, { projects: UpdateProject[] }>({
      query: (projects) => ({
        url: `/building/building-project-period/update`,
        method: 'PUT',
        body: { associations: projects.projects },
      }),
      invalidatesTags: ['Project'],
    }),
  }),
});

export const { useListByPeriodQuery, useCreateProjectMutation, useUpdateProjectsMutation } = projectApi;
