export default interface AccountingExpense {
  expense: {
    id: number;
    code: string;
    description: string;
  };
  buildings: Array<string>;
}

export interface FullAccountExpense {
  buildings: Array<{
    building: {
      id: number;
      name: string;
      acronym: string;
      active: boolean;
    };
    subTypes: Array<{
      code: string;
      name: string;
    }>;
  }>;
  expense: {
    id: number;
    code: string;
    description: string;
  };
}

export interface NewOrUpdateAccountingExpense {
  buildings: Array<{
    buildingId: number;
    subTypes: Array<{
      name: string;
      code: string;
    }>;
  }>;
  expense: {
    code: string;
    description: string;
  };
}

export interface IncomingAccountingExpense {
  code: string;
  description: string;
  date: string;
  value: number;
}

export enum SharedExpensesProcessingStatus {
  NO_EXPENSES = 'NO_EXPENSES',
  RETRIEVING_EXPENSES = 'RETRIEVING_EXPENSES',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}
