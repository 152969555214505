import { ProjectCategoryResponse, ProjectResponse } from '@/app/models/costcenter';
import { useCostCentersQuery } from '@/app/services/costcenterApi';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, InputAdornment, OutlinedInput } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThrottle } from 'react-use';

const ON_CHANGE_DELAY = 1000; // 1 second

function displayCostCenter(costCenter: ProjectResponse | null) {
  if (costCenter) {
    return `${costCenter.code} - ${costCenter.name}`;
  }
  return '';
}

interface CostCenterSearchProps {
  selectedCostCenter: ProjectCategoryResponse | null;
  setCostCenter: Dispatch<SetStateAction<ProjectCategoryResponse | null>>;
}

export default function CostCenterSearch(props: CostCenterSearchProps) {
  const { selectedCostCenter, setCostCenter } = props;
  const [costCenterText, setCostCenterText] = useState(selectedCostCenter ? displayCostCenter(selectedCostCenter) : '');
  const [searchCostCenter, setSearchCostCenter] = useState('');
  const throttledSearchTerm = useThrottle(searchCostCenter, ON_CHANGE_DELAY);
  const { data: costCenters } = useCostCentersQuery(throttledSearchTerm, {
    skip: searchCostCenter?.trim().length === 0,
  });

  const { t } = useTranslation('projects');

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disableClearable
      filterOptions={(option) => option}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      onChange={(event, newValue) => {
        setCostCenter(newValue as ProjectCategoryResponse);
      }}
      inputValue={costCenterText}
      onInputChange={(event, newInputValue) => {
        setCostCenterText(newInputValue);
        if (newInputValue.length > 0) {
          setSearchCostCenter(newInputValue);
        }
      }}
      options={costCenters?.content || []}
      getOptionLabel={(option) => (typeof option === 'string' ? option : displayCostCenter(option))}
      renderOption={(params, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
          {displayCostCenter(option)}
        </Box>
      )}
      renderInput={(params) => (
        <OutlinedInput
          fullWidth
          placeholder={t('enter.cost.center.name.or.code') as string}
          ref={params.InputProps.ref}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          inputProps={{
            ...params.inputProps,
            style: {
              paddingRight: '10px',
              paddingTop: '0px',
              paddingBottom: '0px',
              paddingLeft: '10px',
              fontSize: '13px',
            },
          }}
        />
      )}
    />
  );
}
