import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmProcessProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  buildingAcronym: string;
}

function ConfirmProcess(props: ConfirmProcessProps) {
  const { open, handleClose, handleConfirm, buildingAcronym } = props;
  const { i18n, t } = useTranslation('process');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Processar Custos</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ao confirmar, os custos processados serão associados ao Centro de Custo do Prédio. Tem certeza que deseja
          prosseguir?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          Não
        </Button>
        <Button variant="primary" onClick={handleConfirm} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmProcess;
