import { BuildingProject } from '@/app/models/buildings';
import {
  useDisableBuildingMutation,
  useGetBuildingsQuery,
  useUpdateBuildingMutation,
} from '@/app/services/buildingsApi';
import AddBuildingDrawer from '@/components/buildings/AddBuildingDrawer';
import TextButton from '@/components/buildings/TextButton';
import BuildingTable from '@/components/buildings/table';
import CheckOption from '@/components/common/CheckOption';
import LateralDrawer from '@/components/common/LateralDrawer';
import SearchField from '@/components/common/SearchField';
import PeriodDetails from '@/components/periodDetails/PeriodDetails';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function Buildings() {
  const { t } = useTranslation(['buildings', 'common']);
  const [buildingSearch, setBuildingSearch] = useState('');
  const [showDisabledBuildings, setShowDisabledBuildings] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data: buildings } = useGetBuildingsQuery({ searchTerm: buildingSearch, active: !showDisabledBuildings });
  const [disableBuilding] = useDisableBuildingMutation();
  const [editing, setEditing] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null as BuildingProject | null);

  const [updateBuilding] = useUpdateBuildingMutation();

  function handleClose() {
    setShowCreateModal(false);
    setEditing(false);
    setSelectedBuilding(null);
  }

  async function handleDelete(building: BuildingProject) {
    const response = await disableBuilding(building.building.id);

    if ('data' in response) {
      toast.success(
        t('Prédio desabilitado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    handleClose();
  }

  async function handleReOpen(building: BuildingProject) {
    const response = await updateBuilding({
      building: {
        id: building.building.id,
        name: building.building.name,
        acronym: building.building.acronym,
        active: true,
      },
      projects: building.projects.map((b) => ({
        code: b.code,
        category: b.category,
      })),
    });

    if ('data' in response) {
      toast.success(
        t('Prédio desabilitado com sucesso', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    handleClose();
  }

  function handleOpenEdit(building: BuildingProject) {
    setSelectedBuilding(building);
    setEditing(true);
    setShowCreateModal(true);
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '20px',
      }}
    >
      <PeriodDetails />
      <Typography style={{ marginTop: '1.5rem' }} variant="h1">
        {t('common:buildings')}
      </Typography>

      <div style={{ width: '100%', display: 'flex', marginTop: '24px', marginBottom: '16px', alignItems: 'center' }}>
        <SearchField
          searchTerm={buildingSearch}
          setSearchTerm={setBuildingSearch}
          placeholder={t('search.building.acronym.or.name')}
        />
        <CheckOption
          style={{ marginLeft: '24px' }}
          checked={showDisabledBuildings}
          setChecked={setShowDisabledBuildings}
          text={t('show.only.disabled.buildings')}
        />
        <TextButton
          style={{ marginLeft: 'auto' }}
          text={t('new.building.plus')}
          onClick={() => {
            setEditing(false);
            setShowCreateModal(true);
          }}
        />
      </div>

      <BuildingTable
        onRowSelect={handleOpenEdit}
        onDelete={handleDelete}
        onReOpen={handleReOpen}
        items={buildings || []}
        isClosed={showDisabledBuildings}
      />
      <LateralDrawer open={showCreateModal} setOpen={setShowCreateModal} position="right">
        <AddBuildingDrawer
          handleClose={handleClose}
          editing={editing}
          selectedBuilding={selectedBuilding}
          handleDelete={handleDelete}
          handleReOpen={handleReOpen}
          isClosed={showDisabledBuildings}
        />
      </LateralDrawer>
    </div>
  );
}
export default Buildings;
